const BotaoZap =() => {

    return(
        <>
         <button id='zap' onClick={() =>  window.open("http://wa.me/557391426666", "_black")} 
                                        className=' bg-primary-orange   text-white 
                                        rounded-full
                                        md: w-[230px]
                                        md: h-[50px]
                                        ml-8 m-4 
                                        border-2 border-white
                                        shadow-md
                                        hover:-translate-y-1
                                        hover:scale-110
                                        
                                        hover:bg-primary-focus duration-300'>
                    Falar com o vendedor
                </button>  
        </>
    )
}

export default BotaoZap